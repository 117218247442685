<template>
  <div>
    <div class="disFlex">
      <div
        class="km"
        :class="{ changeBg: kmHover }"
        @click="handleView('main')"
      >
        <div class="headerTitle">
          <img src="@/assets/intro_logo.png" alt="" />
        </div>
        <div class="kmBg">
          <img src="@/assets/kmImg.jpeg" alt="" />
        </div>
        <div class="kmText NM_F">
          <div>태흥당</div>
          <div>한의원</div>
        </div>
        <div class="centerImg"></div>
      </div>
      <div
        class="hp"
        :class="{ changeBg: !kmHover }"
        @click="handleView('other')"
      >
        <div class="hpBg">
          <img src="@/assets/hpImg.jpg" alt="" />
        </div>
        <div class="hpText NM_F">
          <div>태흥당</div>
          <div>한방병원</div>
        </div>
        <div class="centerImg"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      kmHover: true,
    };
  },
  mounted() {
    this.checkDevice();
  },
  methods: {
    checkDevice() {
      const interval = 4000;
      if (window.innerWidth < 1200) {
        setInterval(() => {
          this.kmHover = !this.kmHover;
        }, interval);
      }
    },
    hoverImg(index) {
      if (index === "km") {
        this.kmHover = true;
      } else {
        this.kmHover = false;
      }
    },

    handleView(type) {
      if (type === "main") window.location.href = "https://taeheungdang.com";
      else window.location.href = "http://thdang.co.kr";
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Faustina:wght@300;400&family=Manrope:wght@400;500;700&family=Nanum+Myeongjo:wght@400;700;800&display=swap");

.NM_F {
  font-family: "Faustina", "Nanum Myeongjo", serif;
}

* {
  margin: 0;
  padding: 0;
}
.headerTitle {
  position: absolute;
  top: 5%;
  left: 4%;
  width: clamp(100px, 10vw, 150px);
  z-index: 10;
  transition: all 0.5s ease;
  img {
    width: 100%;
  }
}

.disFlex {
  display: flex;
}

.km {
  background: #eff2e8;
}
.hp {
  background: #eee2dc;
}

.km,
.hp {
  flex-basis: 50%;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  .centerImg {
    position: absolute;
    z-index: 9;
    width: 33%;
    height: 40vh;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 250px;
    img {
      width: 100%;
    }
  }
}

.kmBg {
  opacity: 0;
  scale: 1.1;
}
.hpBg {
  opacity: 0;
  scale: 1.1;
}
.kmBg,
.hpBg {
  transition: all 0.4s ease;
  img {
    filter: brightness(0.7);
    width: 100%;
    height: 100vh;
    object-fit: cover;
    display: block;
  }
}

.km:hover {
  .headerTitle {
    filter: brightness(0) invert(1);
  }
  .kmBg {
    opacity: 1;
    scale: 1;
  }
  .kmText {
    color: white;
  }
}
.hp:hover {
  .hpBg {
    opacity: 1;
    scale: 1;
  }
  .hpText {
    color: white;
  }
}

.km .centerImg {
  background: url("@/assets/kmImg.jpeg") no-repeat 100%;
  background-size: cover;
}

.hp .centerImg {
  background: url("@/assets/hpImg.jpg") no-repeat 100%;
  background-size: cover;
}

.kmText,
.hpText {
  position: absolute;
  top: 25%;
  left: 50%;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  width: 10%;
  min-width: 240px;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease;
}

.kmText {
  color: #00615b;
}
.hpText {
  color: #744443;
}

.kmText div:first-child,
.hpText div:first-child {
  font-size: 20px;
}

@media all and (max-width: 1199px) {
  .headerTitle {
    top: 7%;
    left: 9%;
    img {
      // filter: brightness(0) invert(1);
    }
  }
  .disFlex {
    flex-direction: column;
  }
  .km {
    background: #eff2e8;
  }

  .km,
  .hp {
    .kmBg,
    .hpBg {
      transition: all 2s ease;
      height: 50vh;
    }
    .centerImg {
      position: absolute;
      z-index: 9;
      width: 45%;
      height: 28vh;
      transform: translate(-50%, -50%);
      left: 50%;
      border-radius: 250px;
      img {
        width: 100%;
      }
    }
  }

  .km {
    .centerImg {
      top: 60%;
    }
  }
  .hp {
    .centerImg {
      top: 55%;
    }
  }

  .kmText {
    top: 25%;
  }
  .hpText {
    top: 20%;
  }
  .kmText,
  .hpText {
    position: absolute;
    div {
      position: relative;
      font-size: 16px !important;
    }
  }

  .changeBg {
    .headerTitle {
      filter: brightness(0) invert(1);
    }
    .kmBg,
    .hpBg {
      opacity: 1;
      scale: 1;
    }
    .kmText,
    .hpText {
      color: white;
    }
  }
}
</style>
